@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Noto+Sans+Arabic:wght@100..900&family=Oswald:wght@200..700&display=swap);
[dir="rtl"] body {
	font-size: 16px;
	font-family: "Cairo", sans-serif !important;
	font-optical-sizing: auto;
	line-height: 1.6;
	font-weight: 300;
	text-align: right;
}

[dir="rtl"] body .navbar-nav .nav-item.header-search {
	margin-right: auto;
	margin-left: 0;
}

[dir="rtl"] body .header-search .btn-search {
	right: auto;
	left: 10px;
}

[dir="rtl"] body .header__top .logo {
	float: right;
}
[dir="rtl"] body .header__call {
	margin: 0 5px;
}
[dir="rtl"] body .header__top--action li:not(:last-child) a {
	border-right: 0;
	border-left: 1px solid #ed192d;
	padding-right: 0px;
	padding-left: 20px;
	margin-right: 0;
	margin-left: 20px;
}
[dir="rtl"] .our__stories--block {
	padding: 70px 8vw 70px 0;
}
[dir="rtl"] .our__stories__slider .swiper-button-prev {
	left: auto;
	right: 17%;
}
[dir="rtl"] .our__stories__slider .swiper-button-next {
	left: 17%;
	right: auto;
}
[dir="rtl"] .side-menu-item {
	text-align: left;
}

